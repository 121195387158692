import React from "react";
import { Link } from "gatsby";

export const Heading = ({
  herosection,
  navbar,
  slug,
  locale,
  isMobileView,
}) => {
  return (
    <div className="service_upper_class">
      <div className="serviceHeroSectionTechnologies " id="heading">
        <div className="containerwrap">
          <h4
            className={` ${
              isMobileView
                ? "serviceHeroSectionTitle "
                : "serviceHeroSectionTitle "
            }`}
          >
            {herosection?.title}
          </h4>
          <h1
            className={` ${
              isMobileView
                ? "font-weight-400 font-size-30 roboto text-white text-center line-height-35"
                : "font-weight-400 font-size-64 roboto text-white text-center line-height-85"
            }`}
          >
            {herosection.heading1}
            <span
              className={` ${
                isMobileView
                  ? "font-weight-400 font-size-30 roboto text-white text-center line-height-35"
                  : "font-weight-400 font-size-64 roboto  line-height-85"
              }`}
            >
              {herosection.heading2}
            </span>
          </h1>
          <div
            className={` ${
              isMobileView
                ? "serviceHeroSectionDescription open-sans capitalize"
                : "serviceHeroSectionDescription open-sans capitalize mb-5 pb-5"
            }`}
            dangerouslySetInnerHTML={{ __html: herosection.des1 }}
          />

          <div
            className={` ${
              isMobileView ? "opsBtnwrap middelBtn p-0" : "opsBtnwrap middelBtn"
            }`}
          >
            <Link className="opsBtnlink" to={`/contact-us/`}>
              <p
                className={` ${
                  isMobileView
                    ? " font-weight-400 capitalize font-size-14 line-height-20"
                    : " font-weight-700 capitalize"
                }`}
              >
                {herosection.btn}
              </p>
            </Link>
          </div>
        </div>
      </div>
      <div className="serviceNavSection"></div>
    </div>
  );
};
