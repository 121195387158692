import React from "react";
import check from "../../images/check.svg";

export const Overview = (overviewData) => {
  const { isMobileView } = overviewData;
  return (
    <div className="overview-technologies serviceHotspot pb-0" id="overview">
      <div
        className={` ${isMobileView ? "global-margin" : "global-margin pt-5"}`}
      >
        <div
          className={` ${
            isMobileView ? "overviewTitle  text-center mb-0" : "overviewTitle "
          }`}
        >
          {overviewData.overview.title}
        </div>
        <h2
          className={` ${
            isMobileView
              ? "overviewHeading roboto m-0 text-center"
              : "overviewHeading roboto m-0"
          }`}
        >
          {overviewData.overview.heading}
        </h2>
        <div
          className={` ${
            isMobileView
              ? "overviewDescriptionSection lh-1 m-0 text-center "
              : "overviewDescriptionSection lh-1 m-0  "
          }`}
        >
          <div
            className="overviewDescriptionTechnologies "
            dangerouslySetInnerHTML={{ __html: overviewData.overview.des }}
          />
        </div>
      </div>
      <div className="background-color">
        <div className="global-margin">
          {overviewData.overview.heading2 && (
            <>
              <h2 className="overviewHeading mt-md-5 mt-3 roboto ">
                {overviewData.overview.heading2}
              </h2>
              <div className="overviewDescriptionSection lh-1 ">
                <div
                  className="overviewDescriptionTechnologies"
                  dangerouslySetInnerHTML={{
                    __html: overviewData.overview.des1,
                  }}
                />
              </div>
            </>
          )}
          <h5
            className={` ${
              isMobileView
                ? "overviewHeading  roboto text-white  text-center"
                : "overviewHeading mt-5 roboto m-0 text-white pt-5"
            }`}
          >
            {overviewData.overview.benefitsTitle}
          </h5>
          <div className="d-md-flex justify-content-md-start col-md-12">
            <div
              className={` ${
                isMobileView
                  ? "me-md-5 col-md-6  pt-5 "
                  : "me-md-5 col-md-6  pt-5 mt-5"
              }`}
            >
              <ul className="col-md-12 col-11 p-0">
                {overviewData.overview.benefits.map((benefit, index) => (
                  <div className="d-flex col-md-12 col-11 mb-3">
                    <span className="me-2">
                      <img src={check} width="20px" height="20px" alt="check" />
                    </span>
                    <span
                      className={` ${
                        isMobileView
                          ? " font-size-14 line-height-20 font-weight-400 col-md-11 col-10 text-light  capitalize  "
                          : "overviewUl col-md-11 col-10 text-light  capitalize ms-2 mt-1"
                      }`}
                      key={index}
                      dangerouslySetInnerHTML={{ __html: benefit }}
                    />
                  </div>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
