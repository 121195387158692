import React, { useState, useEffect, useCallback } from "react";
import { navigate } from "gatsby";
import VisibilitySensor from "react-visibility-sensor";
import { graphql } from "gatsby";
import "../styles/newTechnologies.scss";
import SEO from "../components/seo";
import Layout from "../components/layoutnew";
import { Heading } from "../components/newTechnologies/Heading";
import { Overview } from "../components/newTechnologies/overview";
import { Problem } from "../components/newTechnologies/problem";
import { Solution } from "../components/newTechnologies/solution";
import { OpsioOfferings } from "../components/newTechnologies/opsioOfferings";
import { IdealUseCases } from "../components/newTechnologies/idealUseCases";
import { ManagedCloudServices } from "../components/newTechnologies/managedCloudServices";
import Faq from "../components/newServices/faq";

const NewTechnologies = ({ data }) => {
  const getNewTechnologiesData = (data) => {
    const values = data.allStrapiNewTechnology.edges[0].node;
    return values;
  };
  const getData = getNewTechnologiesData(data);
  const [isMobileView, setIsMobileView] = useState(false);

  const checkMobileView = () => {
    setIsMobileView(window.innerWidth < 768);
  };

  useEffect(() => {
    checkMobileView();
    window.addEventListener("resize", checkMobileView);
    return () => {
      window.removeEventListener("resize", checkMobileView);
    };
  }, []);

  console.log({ getData });
  return (
    <Layout slug={getData.slug} locale={getData.locale}>
      <SEO
        slug={getData.slug}
        locale={getData.locale}
        title={getData.metaTitle}
        description={getData.metaDes}
        keywords={getData.metaKeywords}
      />
      <Heading
        herosection={getData.hero}
        locale={getData.locale}
        slug={getData.slug}
        navbar={getData.menubar}
        isMobileView={isMobileView}
      />

      <VisibilityWrap slug={getData.slug} sectionId="overview">
        <Overview
          overview={getData.overview}
          themeImages={getData.themeImage}
          isMobileView={isMobileView}
        />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="idealUseCases">
        <IdealUseCases
          icons={getData.icons1}
          usecases={getData.usecases}
          isMobileView={isMobileView}
        />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="theProblem">
        <Problem problem={getData.problem} isMobileView={isMobileView} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="theSolution">
        <Solution solution={getData.solution} icons={getData.icons3} />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="opsioOfferings">
        <OpsioOfferings
          opsioOfferings={getData.opsioOfferings}
          icons={getData.icons3}
          isMobileView={isMobileView}
        />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="managedCloudServices">
        <ManagedCloudServices
          icons={getData.icons2}
          icons1={getData.icons3}
          managedCloudServices={getData.services}
          isMobileView={isMobileView}
        />
      </VisibilityWrap>

      <VisibilityWrap slug={getData.slug} sectionId="managedCloudServices">
        <Faq
          title={getData.faqTitle}
          faqs={getData.faqs}
          isMobileView={isMobileView}
        />
      </VisibilityWrap>
    </Layout>
  );
};
export default NewTechnologies;

const VisibilityWrap = ({ slug, sectionId, children }) => {
  const [active, setActive] = useState(false);
  const [screenHeight, setScreenHeight] = useState(0);

  setTimeout(() => {
    setActive(true);
  }, 1200);

  useEffect(() => {
    const handler = () => {
      setActive(false);
      setTimeout(() => {
        setActive(true);
      }, 1000);
    };

    setScreenHeight(window.innerHeight);

    const upperClass = document.getElementsByClassName(
      "service_upper_class"
    )[0];

    upperClass.addEventListener("click", handler);

    return () => {
      upperClass.removeEventListener("click", handler);
    };
  });

  return (
    <VisibilitySensor
      active={active}
      partialVisibility={true}
      delayedCall={true}
      offset={{
        top: screenHeight > 0 ? screenHeight / 2 : 300,
        bottom: screenHeight > 0 ? screenHeight / 2 : 300,
      }}
      onChange={(isVisible) => {
        if (isVisible) {
          navigate(`/${slug}/#${sectionId}`);
        }
      }}
    >
      {children}
    </VisibilitySensor>
  );
};

export const query = graphql`
  query NewTechnologies($slug: String!, $locale: String!) {
    allStrapiNewTechnology(
      filter: { locale: { eq: $locale }, slug: { eq: $slug } }
    ) {
      edges {
        node {
          locale
          slug
          metaTitle
          metaDes
          metaKeywords
          hero {
            btn
            des1
            heading1
            heading2
            title
          }
          overview {
            benefits
            benefitsTitle
            des
            heading
            title
            heading2
            des1
          }
          usecases {
            bulletHeading
            bulletPoints

            challenges {
              title
              des
            }
            bulletPointsWithDesc {
              id
              title
              des
            }
            des
            heading
            title
            id
          }
          problem {
            challengeHeading
            bulletHeading2
            bulletPoints2
            challengeDes
            challenges2 {
              title
              des
            }
            des3
            challenges {
              des
              title
            }
            bulletPoints
            des1
            des2
            heading
            title
            id
          }
          solution {
            bulletHeading
            bulletPoints
            des1
            des2
            des3
            heading2
            heading
            title
            id
            highlights1
          }
          menubar {
            l1
            l2
            l3
            l4
            l5
            l6
          }
          opsioOfferings {
            bulletHeading
            bulletPoints
            heading
            bulletPointsWithDes {
              title
              des
            }
            des
            des1
            des2
            des3
            highlights
            heading2
            title
            id
          }
          services {
            bulletPoints
            cta
            des1
            des2
            footnote
            highlights1
            heading
            title
            id
          }
          icons1 {
            url
          }
          icons2 {
            url
          }
          icons3 {
            url
          }
          themeImage {
            url
          }
          faqTitle
          faqs {
            question
            answer {
              data {
                answer
              }
            }
          }
        }
      }
    }
  }
`;
