import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import amazon from "../../images/amazon-blue.svg";
import micro from "../../images/micro-blue.svg";
import cloud from "../../images/cloud-blue.svg";

export const OpsioOfferings = (opsioOfferingsData) => {
  const { isMobileView } = opsioOfferingsData;

  return (
    <>
      <div className="background-color">
        <div
          className="ourApproachOpsioOfferings serviceHotspot py-5 background-color"
          id="opsioOfferings"
        >
          <div className="global-margin background-color">
            <div
              className={` ${
                isMobileView
                  ? "approchTitle  text-center mt-5"
                  : "approchTitle  text-start"
              }`}
            >
              {opsioOfferingsData.opsioOfferings.title}
            </div>
            <div className="approchHeading text-light text-start">
              {opsioOfferingsData.opsioOfferings.heading}
            </div>
            <div
              className={` ${
                isMobileView
                  ? "approchdescription mb-3  capitalize text-center text-light"
                  : "approchdescription mb-3  capitalize text-start text-light"
              }`}
              dangerouslySetInnerHTML={{
                __html: opsioOfferingsData.opsioOfferings.des,
              }}
            />
          </div>
        </div>
        <div className="processOpsioOfferings  serviceHotspot pt-0 pb-0 background-color">
          <div className="global-margin">
            <div className="prorcss_wrap">
              <div
                className={` ${
                  isMobileView
                    ? "ourApproachOpsioOfferings serviceHotspot pt-0 mb-0 background-color d-flex flex-column"
                    : "ourApproachOpsioOfferings serviceHotspot pt-0 mb-0 background-color d-flex justify-content-between"
                }`}
              >
                <div className={` ${isMobileView ? "col-12" : "col-md-6 "}`}>
                  <div
                    className={` ${
                      isMobileView
                        ? "taperzoid mx-auto font-size-12"
                        : "taperzoid mx-auto py-3"
                    }`}
                  ></div>
                  <div
                    className={` ${
                      isMobileView
                        ? "px-2 backround-card"
                        : "p-5 backround-card"
                    }`}
                  >
                    <div
                      className={` ${
                        isMobileView
                          ? "approchHeading text-center font-size-16 py-1 "
                          : "approchHeading text-start py-4 "
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: opsioOfferingsData.opsioOfferings.bulletHeading,
                      }}
                    />
                    <div
                      className={` ${
                        isMobileView
                          ? "approchdescription w-100 font-size-9  text-start text-white text-center capitalize mb-2"
                          : "approchdescription w-100 mb-3  text-start text-light capitalize"
                      }`}
                    >
                      {opsioOfferingsData.opsioOfferings.des1}
                    </div>
                  </div>
                </div>

                {/* Content for laptop/desktop view */}

                <div className={` ${isMobileView ? "col-12" : "col-md-6 "}`}>
                  <div
                    className={` ${
                      isMobileView
                        ? "taperzoid mx-auto font-size-12"
                        : "taperzoid mx-auto py-3"
                    }`}
                  ></div>
                  <div
                    className={` ${
                      isMobileView
                        ? "px-2 backround-card"
                        : "p-5 backround-card"
                    }`}
                  >
                    <div
                      className={` ${
                        isMobileView
                          ? "approchHeading text-center font-size-16 py-1 "
                          : "approchHeading text-start py-4 "
                      }`}
                    >
                      {opsioOfferingsData.opsioOfferings.heading2}
                    </div>
                    <div className="approchdescription mb-3  text-light text-start capitalize">
                      {opsioOfferingsData.opsioOfferings.des2}
                    </div>
                    <div className="approchdescription mb-3 open-sans text-light text-start">
                      {opsioOfferingsData.opsioOfferings.des3}
                    </div>
                    <div className="d-flex justify-content-between p-2">
                      <img
                        className="p-2 py-2"
                        alt="amazon"
                        src={amazon}
                        width="70"
                        height="auto"
                        loading="lazy"
                      />
                      <div className="left-green-border"></div>
                      <img
                        className="p-2"
                        alt="micro"
                        src={micro}
                        width="100"
                        height="auto"
                        loading="lazy"
                      />
                      <div className="left-green-border"></div>
                      <img
                        className="p-2"
                        alt="cloud"
                        src={cloud}
                        width="100"
                        height="auto"
                        loading="lazy"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
