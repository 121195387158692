import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const Problem = ({
  problem: {
    id,
    title,
    heading,
    des1,
    des2,
    challengeHeading,
    challenges,
    challenges2,
    challengeDes,
    bulletPoints,
    bulletHeading2,
    bulletPoints2,
    des,
  },
  isMobileView,
}) => {
  return (
    <>
      {id === "consltancy" ? (
        <>
          <div
            className="ourApproachWhiteOfferings serviceHotspot"
            id="theProblem"
          >
            <div className="containerwrap">
              <div className="approchTitle ">{title}</div>
              <div
                className="approchHeading mb-0 roboto"
                dangerouslySetInnerHTML={{ __html: heading }}
              />
              <div
                className="approchdescription mt-5 open-sans"
                dangerouslySetInnerHTML={{ __html: des1 }}
              />
            </div>
          </div>
          <div className="processOpsioOfferings serviceHotspot pb-0 pt-5">
            <div className="containerwrap">
              <div className="prorcss_wrap w-100">
                <div className="d-md-flex col-md-12 w-100 justify-content-md-center ">
                  <div className="processRightSectionServices w-100">
                    <div className="inner_process d-md-flex justify-content-md-center w-100">
                      <div className="inner_process_left d-md-flex  w-100 col-md-12 col-12 flex-md-wrap">
                        {bulletPoints &&
                          bulletPoints.map((bulletPoint, index) => (
                            <div
                              className="processDetails col-md-6 col-12"
                              key={index}
                            >
                              <div className="number">
                                {index < 9 ? `0${index + 1}` : `${index + 1}`}
                              </div>
                              <div className="process_content_solution_purple open-sans">
                                {bulletPoint}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="partnerSecTechnologies serviceHotspot pb-0"
            id="theProblem"
          >
            <div className="global-margin">
              <div className="partsecleftTechnologies partseccover">
                <div className="problem-technologies serviceHotspot">
                  <div
                    className={` ${
                      isMobileView
                        ? "overviewTitle  mb-2 text-center mb-0"
                        : "overviewTitle  mb-2 text-start mb-0"
                    }`}
                  >
                    {title}
                  </div>
                  {challengeDes && (
                    <>
                      <div className=" ">
                        <div
                          className={` ${
                            isMobileView
                              ? "global-heading-style text-start mb-5 pb-5 roboto capitalize text-cent"
                              : "global-heading-style text-start mb-5 pb-5 roboto capitalize"
                          }`}
                          dangerouslySetInnerHTML={{ __html: challengeDes }}
                        />
                      </div>
                      <div className="docker-service">
                        <Slider className="partner-slider d-flex justify-content-center">
                          {challenges2.map(({ title, des }, index) => (
                            <div
                              key={index}
                              className={` ${
                                isMobileView
                                  ? "partsecleft"
                                  : "partsecleft px-5 w-85"
                              }`}
                            >
                              <div className="leftSection  w-100">
                                <div className="secHeading m-0 pb-3 roboto">
                                  {title}
                                </div>
                                <dic
                                  className={` ${
                                    isMobileView
                                      ? " global-services-desc  capitalize"
                                      : " global-services-desc  capitalize"
                                  }`}
                                  dangerouslySetInnerHTML={{ __html: des }}
                                />
                              </div>
                            </div>
                          ))}
                        </Slider>
                      </div>
                    </>
                  )}
                  <div
                    className="overviewHeading mx-0 text-start roboto"
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />

                  <div className="overviewDescriptionSection  mx-0">
                    <div
                      className="overviewDescriptionTechnologies text-start"
                      dangerouslySetInnerHTML={{ __html: des1 }}
                    />
                  </div>
                  {des2 && (
                    <div className="overviewDescriptionSection  mx-0">
                      <div
                        className="overviewDescriptionTechnologies text-start"
                        dangerouslySetInnerHTML={{ __html: des2 }}
                      />
                    </div>
                  )}

                  <div
                    className={` ${
                      isMobileView
                        ? "overviewHeading font-size-20 font-weight-700 m-0 text-start roboto capitalize text-center"
                        : "overviewHeading mx-0 text-start my-5 roboto capitalize"
                    }`}
                  >
                    {challengeHeading}
                  </div>
                  <div className="docker-service">
                    <Slider className="partner-slider">
                      {challenges?.map(({ title, des }, index) => (
                        <div
                          key={index}
                          className={` ${
                            isMobileView
                              ? "partsecleft"
                              : "partsecleft px-5 w-85"
                          }`}
                        >
                          <div
                            className={` ${
                              isMobileView
                                ? "leftSection w-100"
                                : "leftSection w-100 ps-5"
                            }`}
                          >
                            <div
                              className={` ${
                                isMobileView
                                  ? "global-title-style roboto pb-4"
                                  : "global-title-style me-5 roboto pb-4"
                              }`}
                            >
                              {title}
                            </div>
                            <div
                              className=" global-services-desc capitalize"
                              dangerouslySetInnerHTML={{ __html: des }}
                            />
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="global-margin">
            {bulletHeading2 && (
              <div className="processOpsioOfferings serviceHotspot pb-0 pt-0">
                <div className="">
                  <div className="prorcss_wrap w-100">
                    <div className="ourApproachWhiteOfferings w-100 serviceHotspot pt-0 mb-0">
                      <div
                        className={` ${
                          isMobileView
                            ? "approchHeading text-start roboto mb-0 py-2 mt-5 mb-2"
                            : "approchHeading text-start roboto mb-0 py-5 mt-5 mb-5"
                        }`}
                      >
                        {bulletHeading2}
                      </div>
                    </div>

                    <div className="d-flex ">
                      <div className="processRightSectionServices w-100">
                        <div className="inner_process d-md-flex justify-content-md-center w-100">
                          <div className="inner_process_left d-md-flex justify-content-md-start  w-100 col-md-12 col-12 flex-md-wrap">
                            {bulletPoints2.map((bulletPoint, index) => (
                              <div
                                className={`processDetails col-md-6 col-12  ${
                                  index === 3 || index === 7
                                    ? "me-md-0 pe-md-0"
                                    : ""
                                } ${
                                  (id === "importance" && index === 1) ||
                                  index === 3
                                    ? "me-md-0 pe-md-0"
                                    : ""
                                }`}
                                key={index}
                              >
                                <div className="number">
                                  {index < 9
                                    ? `0${index + 1}.`
                                    : `${index + 1}.`}
                                </div>
                                <div className="process_content_solution_purple  text-capitalize">
                                  {bulletPoint}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ourApproachWhiteOfferings w-100 serviceHotspot pt-0 mb-0 pb-md-5 text-white text-center ">
                  <div className="containerwrap">
                    <div className="approchDescription open-sans">{des2}</div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};
